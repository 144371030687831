var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-wallet", {
    attrs: {
      "total-left-col-items": 1,
      "has-draggable": "",
      "total-right-col-items": _vm.totalRightColItems,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "leftColItem1",
          fn: function () {
            return [_c("module-send")]
          },
          proxy: true,
        },
        {
          key: "rightColItem1",
          fn: function () {
            return [
              _c("module-tokens-value", {
                attrs: { draggable: _vm.hasHistory },
              }),
            ]
          },
          proxy: true,
        },
        _vm.hasHistory
          ? {
              key: "rightColItem2",
              fn: function () {
                return [
                  _c("module-transfer-history", { attrs: { draggable: "" } }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }